const line_banner = document.querySelector('.line-banner');
const line_iframe = document.querySelector('.line-it-button');


$(function() {
	!function() {
		return $("#home").length ? void $(".gallery").flickity({
			autoPlay: !0,
			wrapAround: !0,
			prevNextButtons: !1,
			lazyLoad: !0
		}) : !1
	}(),
	function() {
		if (!$("#reserve-form").length)
			return !1;
		for (var o = window.location.search.substring(1), e = o.split("&"), n = {}, t = 0; t < e.length; ) {
			var l = e[t].split("=")
			  , a = decodeURIComponent(l[0])
			  , i = decodeURIComponent(l[1]);
			n[a] = i,
			t++
		}
		$("#event_title").val(n.title),
		$("#event_date").val(n.date)
	}(),
	function() {
		if (!$("body.tour").length)
			return !1;
		var o = function() {
			var o;
			$(".tour-section__photo").each(function() {
				var e = $(this).width();
				(!o || o && o > e) && (o = e)
			}),
			$(".tour-section__photo").each(function() {
				$(this).height(o).css("overflow", "hidden")
			})
		};
		$(window).on("resize", o),
		o()
	}(),
	$('select option[value=""]').html("選択してください"),
	$("#globalMenu").each(function(o, e) {
		var n = $("#globalMenu,#globalNavLists");
		$(this).on("click", function(o) {
			n.hasClass("open") ? n.removeClass("open") : (n.addClass("open"),
			o.stopPropagation())
		})
	}),
	$("body").on("click", function(o) {
		$("#globalMenu").hasClass("open") && $("#globalMenu,#globalNavLists").removeClass("open")
	}),
	$("html.touch").length && $("#globalMenu").hasClass("open") && $("#globalMenu").trigger("click")
});


////for line_banner///
scrollHeight = $(document).height();
scrollPosition = $(window).height() + $(window).scrollTop();
footHeight = $(".container-footer__inner").innerHeight();

$(window).scroll(function() {
  scrollHeight = $(document).height();
  scrollPosition = $(window).height() + $(window).scrollTop();
  footHeight = $(".container-footer__inner").innerHeight();
  if ( scrollHeight - scrollPosition  <= footHeight ) {
    $(".line-banner__btn").css({
			"position":"fixed",
			"bottom": $(".container-footer__inner").innerHeight(),
			"right": "0",
			"transition-duration":"0.5s"
    });
  } else {
    $(".line-banner__btn").css({
        "position":"fixed",
        "bottom": "0",
				"right": "0"
    });
  }
}
);
